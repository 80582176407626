import { momentFormat, momentTimeZone } from '@/util/functions/index'

const dateFormat = 'YYYY-MM-DD HH:mm'

const documentDashboardHeaders = [{
  text: 'Patient Name',
  value: 'fullName',
},
{
  text: 'Patient DOB',
  value: 'dob',
  formatter: x => (x ? momentTimeZone(x) : null),

},
{
  text: 'Date Submitted',
  value: 'modifiedAt',
  sortable: true,
  formatter: x => (x ? momentFormat(x, dateFormat) : null),

},
{
  text: 'Document Status',
  value: 'status',
},
{
  text: 'Download PDF',
  value: 'dlPDF',
  align: 'center',
},
]

export default documentDashboardHeaders
