const rxTodayHome = [
  {
    text: 'Patient Name',
    align: 'left',
    value: 'full_name',
    width: '20%',
    groupable: false,
  },
  {
    text: 'Rx Number',
    value: 'documentation',
    align: 'left',

    width: '15%',
    groupable: false,
    sortable: false,
  },
  {
    text: 'Medication',
    align: 'left',
    value: 'BRAND NAME',
    width: '30%',
    groupable: false,
    sortable: false,
  },
  {
    text: 'Strength',
    align: 'left',
    value: 'STRENGTH',
    width: '5%',
    sortable: false,
    groupable: false,
  },
  {
    text: 'Doctor Last name',
    align: 'left',
    value: 'DOCTOR LAST NAME',
    width: '20%',
    groupable: false,
    sortable: false,
  },
  {
    text: 'Qty Dispensed',
    align: 'right',
    value: 'QTY DISPENSE',
    width: '5%',
    groupable: false,
    sortable: false,
  },

]

export default rxTodayHome
