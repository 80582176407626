<template>
  <div>
    <v-card-text id="rx-today-day-picker">
      <v-row class="tw-pt-3">
        <v-col

          cols="12"
          md="4"
        >
          <day-picker-dialog
            :date="currentDate"
            @refreshRxTodayList="populatePrescriptionsList"
          ></day-picker-dialog>
        </v-col>
        <v-col
          cols="12"
          offset-md="4"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            label="Search"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Table https://codepen.io/lzhoucs/pen/aadaJx excellent resource -->
    <v-data-table
      id="rx-today-table"
      v-model="selected"
      dense
      :loading="loadingRxToday"
      :headers="rxTodayHome"
      item-key="RX NUMBER"
      :options.sync="options"
      :server-items-length="prescriptionsLength"
      :items="prescriptions"
      :search="search"
      :items-per-page="this.itemsPerPage"
      :footer-props="{
        'items-per-page-options': rowsPerPage
      }"
      show-group-by
      class="table-kitchen-sink"
    >
      <!-- :sort-by="sortBy"
      :sort-desc="sortDesc" -->
      <!-- action -->
      <template
        #[`item.documentation`]="{ item }"
        :loading="loadingAmend"
      >
        <v-icon
          color="success"
          medium
          class="me-3"
          @click="saveRxInfo(item, item['PATIENT ID'])"
        >
          {{ icons.mdiPencilOutline }}
        </v-icon>
      </template>
      <template #item.full_name="{ item }">
        {{ item['PATIENT LAST NAME'] }}, {{ item['PATIENT FIRST NAME'] }}
      </template>
      <!-- RxDate -->
      <template
        v-for="header in rxTodayHome.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>
      <template
        #[`item.profile`]="{ item }"
        :loading="loadingAmend"
      >
        <v-icon
          color="primary"
          medium
          class="me-3"
          @click="goToPtProfile(item, item['PATIENT ID'])"
        >
          {{ icons.mdiAccountOutline }}
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { ref } from '@vue/composition-api'
import {
  mdiCalendar,
  mdiMagnify,
  mdiPencilOutline,
  mdiAccountOutline,
} from '@mdi/js'
import moment from 'moment'
import { rxTodayHome } from '@/assets/tableHeaders'
import dayPickerDialog from '@/components/datePickers/dayPickerDialog.vue'

export default {
  name: 'RxTodayHome',
  components: {
    dayPickerDialog,
  },
  setup() {
    const search = ref('')

    const date = new Date().toISOString().substr(0, 10)
    const menu = ref(false)
    const modal = ref(false)
    const menuref = ref(null)

    const dates = ref([
      new Date().toISOString().substr(0, 10),
      new Date().toISOString().substr(0, 10),
    ])

    return {
      icons: {
        mdiMagnify,
        mdiCalendar,
        mdiPencilOutline,
        mdiAccountOutline,
      },
      dates,
      search,
      date,
      menu,
      modal,
      menuref,
    }
  },
  computed: {
    ...mapGetters(['PharmacyProfile', 'UserProfile']),
    ...mapState(['User']),
  },
  data() {
    return {
      selected: [],
      loadingRxToday: true,
      rxTodayHome,
      searchPrescription: '',
      prescriptionsLength: 0,
      itemsPerPage: 25,
      options: {
        page: 1,
      },
      groupby: 'PATIENT LAST NAME',
      desserts: [],
      dateFormat: 'YYYY-MM-DD',
      currentDate: moment().format('YYYY-MM-DD'),
      fromDate: moment().format('YYYY-MM-DD'),
      sortBy: 'Rx.RxDate',
      sortDesc: 'DESC',

      prescriptions: [],
      pagination: {
        sortBy: 'date',
      },
      rowsPerPage: [10, 25, 50, { text: 'All', value: -1 }],
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  created() {
    this.refreshRxList(this.currentDate)

    // this.populatePrescriptionsList()
  },
  mounted() {
    this.$root.$on('Refresh_Rx_List', () => {
      this.refreshRxList(this.currentDate)
    })
  },
  methods: {
    getDataFromApi() {
      this.loadingRxToday = true
      this.ApiCall().then(data => {
        this.desserts = data.items
        this.prescriptionsLength = this.prescriptions[0].totalRx
        this.loadingRxToday = false
      })
    },
    ApiCall() {
      return new Promise(async (resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const sort = {
          'RX NUMBER': 'Rx.RXNumber',
          full_name: 'Patient.LastName',
          '': 'Rx.RxDate',
        }
        const desc = {
          true: 'DESC',
          false: 'ASC',
          '': 'DESC',
        }
        this.sortBy = sort[sortBy]
        this.sortDesc = desc[sortDesc]
        this.itemsPerPage = itemsPerPage
        await this.refreshRxList(this.fromDate)

        this.scrollToForm()
        setTimeout(() => {
          resolve({})
        }, 1000)
      })
    },
    scrollToForm() {
      // document.body.scrollTop = document.documentElement.scrollTop = 0;
      document.getElementById('rx-today-day-picker').scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    },
    async saveRxInfo(rx, patientID) {
      this.$store.dispatch('savePatientData', {
        ...(await this.$root.$system.DBAdapterConn.getPatientData(patientID)),
        selectedMedication: [rx],
      })
      this.$router.push({
        name: 'formhub',
      })
    },
    async refreshRxList(dateSelected) {
      this.loadingRxToday = true
      this.fromDate = dateSelected
      this.prescriptions =
        (await this.$root.$system.DBAdapterConn.getPrescriptionsToday(
          this.fromDate,
          this.itemsPerPage,
          this.options.page - 1,
          this.sortBy,
          this.sortDesc,
        )) || []
      if (this.prescriptions.length > 0) {
        this.prescriptionsLength = this.prescriptions[0].totalRx
      } else {
        this.prescriptionsLength = 0
      }
      this.loadingRxToday = false
    },

    // getPrescriptionsToday(fromDate, itemsPerPage, page)
    async populatePrescriptionsList(dateSelected) {
      this.loadingRxToday = true
      this.fromDate = dateSelected
      this.options.page = 1
      this.prescriptions =
        (await this.$root.$system.DBAdapterConn.getPrescriptionsToday(
          this.fromDate,
          this.itemsPerPage,
          this.options.page - 1,
          this.sortBy,
          this.sortDesc,
        )) || []
      if (this.prescriptions.length > 0) {
        this.prescriptionsLength = this.prescriptions[0].totalRx
      } else {
        this.prescriptionsLength = 0
      }
      this.loadingRxToday = false
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
