var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',{attrs:{"id":"rx-today-day-picker"}},[_c('v-row',{staticClass:"tw-pt-3"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('day-picker-dialog',{attrs:{"date":_vm.currentDate},on:{"refreshRxTodayList":_vm.populatePrescriptionsList}})],1),_c('v-col',{attrs:{"cols":"12","offset-md":"4","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Search","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"table-kitchen-sink",attrs:{"id":"rx-today-table","dense":"","loading":_vm.loadingRxToday,"headers":_vm.rxTodayHome,"item-key":"RX NUMBER","options":_vm.options,"server-items-length":_vm.prescriptionsLength,"items":_vm.prescriptions,"search":_vm.search,"items-per-page":this.itemsPerPage,"footer-props":{
      'items-per-page-options': _vm.rowsPerPage
    },"show-group-by":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.documentation",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"me-3",attrs:{"color":"success","medium":""},on:{"click":function($event){return _vm.saveRxInfo(item, item['PATIENT ID'])}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])]}},{key:"item.full_name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item['PATIENT LAST NAME'])+", "+_vm._s(item['PATIENT FIRST NAME'])+" ")]}},_vm._l((_vm.rxTodayHome.filter(function (header) { return header.hasOwnProperty('formatter'); }
      )),function(header){return {key:("item." + (header.value)),fn:function(ref){
      var header = ref.header;
      var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.profile",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"me-3",attrs:{"color":"primary","medium":""},on:{"click":function($event){return _vm.goToPtProfile(item, item['PATIENT ID'])}}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountOutline)+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }