<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
        sm="12"
        order="1"
        class="align-self-end"
      >
        <rx-today></rx-today>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RxToday from '@/components/phoxTables/drug/RxToday.vue'

export default {
  components: {
    RxToday,
  },
  setup() {
    return {}
  },
}
</script>
